.List {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  /* height: 650px; */
}

.headerContainer {
  background: #009ae0;
  color: #fff;
  display: grid;
  place-items: center;
  padding: 16px 0;
  position: relative;
  z-index: 1000;
}

.headerBackIcon {
  position: absolute;
  left: 16px;
  cursor: pointer;
}

.UpperDivision {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 68px; */
  border-bottom: 1px solid lightgrey;
}

.LowerDivision {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 120px);
  /* overflow-y: scroll; */
  /* margin-bottom: 80px; */
}

.foldersContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
}

.Heading {
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  opacity: 0.6;
  padding-left: 16px;
  margin: 16px 0px 0px 0px;
}

.PreviewContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.87);
  height: 100vh;
}

.CategoryList {
  padding: 8px;
}

.CategoryList > div {
  width: 100%;
}

.Card {
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
}

.PreviewCard {
  margin: 80px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 90%;
  position: relative;
}

.ButtonDivision {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.12);
}

.PreviewButton {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.87);
  width: 30%;
  height: 44px;
  background: #fff;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  border: 1px solid lightgrey;
}

.RightButtonContainer {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ModalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  flex-direction: column;
}

.ModalButton {
  width: 85%;
  height: 44px;
  background: #009ae0;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  border: none;
}

.Filter {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
}

.FilterButton {
  padding: 0 16px;
  background: #fcfcfc;
  height: 36px;
  border: 1px solid #e5e5e5;

  border-radius: 4px;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  position: relative;
}

.FilterButton p {
  margin: 0px;
}

.FilterText {
  padding-left: 8px;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  opacity: 0.6;
}

input[type="checkbox"] {
  height: 14px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}
input[type="checkbox"]:active,
input[type="checkbox"]:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}
input[type="checkbox"]:checked {
  background-color: #009ae0;
  border: 1px solid #009ae0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}
input[type="checkbox"]:checked:after {
  content: "\2714";
  font-size: 12px;
  position: absolute;
  top: 0px;
  left: 1px;
  color: #fff;
}
input[type="checkbox"][disabled] {
  background-color: #fff;
  border-color: #e7e7e7;
}
input[type="checkbox"][disabled]:checked:after {
  color: #9d9d9d;
}

.loaderList {
  margin-left: 80%;
  margin-top: 80%;
}

.centeredDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: grey;
}

.noImagesText {
  margin-top: 10px;
}

.filterThreeDot {
  width: 8px;
  height: 8px;
  position: absolute;
  background: lightgreen;
  border-radius: 50%;
  top: 4px;
  right: 5px;
}

.infoText {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 154, 224, 0.12);
  padding: 12px;
  width: 90%;
  margin: 16px auto;
}

.mobileViewContainer {
  width: 100%;
}
