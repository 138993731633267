.previewImageContainer {
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  background: white;
  width: 100%;
}

.previewImageTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid lightgrey;
  padding: 15px;
}

.previewCategoriesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
}

.previewButtonContainer {
  position: absolute;
  bottom: -40%;
  width: 100%;
}

.previewModalClose {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 0;
  top: -40px;
}

.DownloadButton {
  background: #ffca28;
  border-radius: 4px;
  width: 100%;
  padding: 12px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.87);
  outline: none;
  cursor: pointer;
  border: none;
}

.SelectButton {
  width: 100%;
  padding: 12px 0;
  background: #009ae0;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  border: none;
  margin-top: 16px;
}

.previewCategoryImage {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewCategories {
  width: 90%;
  margin-left: 10px;
}

.previewCategoriesHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.previewCategoriesList {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.6);
  word-wrap: break-word;
  margin-top: 4px;
}
