.App {
  text-align: center;
  height: 100vh;
}

@media (max-width: 600px) {
  .App {
    height: 100%;
  }
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

input[type="checkbox"]:checked:after {
  top: 1px;
  left: 3px;
}
