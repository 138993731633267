.Search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0 16px 16px;
  position: relative;
}

.SearchInput {
  height: 36px;
  width: 100%;
  font-size: 14px;
  padding-left: 35px;
  background: #fcfcfc;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
}

.SearchInput ::placeholder {
  color: #000000;
  opacity: 0.6;
}

.SearchImage {
  position: absolute;
  left: 25px;
}
