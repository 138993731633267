.FilterContainer {
  width: 100%;
  height: 95vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.FilterBody {
  border-radius: 12px 12px 0 0;
  background: white;
  margin-top: 50px;
  height: 100%;
  width: 100%;
}

.FilterFooter {
  position: absolute;
  bottom: -40px;
  border-top: 1px solid #e5e5e5;
  width: 100%;
  display: grid;
  place-items: center;
  padding: 24px 0;
}

.FilterListText {
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.87;
}

.FilterListLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 10px; */
}

.FilterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  padding: 28px;
}

.HeaderText {
  display: flex;
  align-items: center;
}

.HeaderActionContainer {
  /* display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; */
}

.HeaderAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #009ae0;
}

.HeaderActionFooter {
  width: 80%;
  background-color: #009ae0;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  padding: 12px 0;
  border-radius: 4px;
}

.FilterContent {
  /* width: 100%;
  height: 100%; */
  padding: 24px;
}

.FilterContentHeading {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  color: #000000;

  mix-blend-mode: normal;
  opacity: 0.6;
}

.FilterTypes {
  display: flex;
  width: 40%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid lightgrey;
  position: relative;
}

.FilterList {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.FilterList > div {
  width: 100%;
}

.CategoryText {
  display: flex;
  width: 100%;
  padding: 20px;
  background: rgb(0, 154, 224, 0.08);
  color: black;
}

.centeredDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: grey;
}
