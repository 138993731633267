.CardItem {
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  border: 1px solid lightgray;
  width: 100%;
}

.CardItem:active {
  border: 1px solid #009ae0;
}

.SelectedCardItem {
  border: 1px solid #009ae0;
}

.mDImageHeading {
  font-size: 14px;
}

.mDImageCategory {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
}

.mDImageContain {
  border-top: 1px solid #e5e5e5;
  padding: 12px;
}
